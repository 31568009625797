import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import HeroChevron from "../../../components/hero/hero-chevron";
import DebitCardsOpenAccount from "../../../components/sign-up/debit-cards-open-account";
import ContactInfo from "../../../components/contact-info/contact-info";
import Icon from "../../../components/custom-widgets/icon";
import ServiceStatesOpenAccountSection from "../../../components/service-states/service-states-open-account-section";
import FeaturesSection from "../../../components/rewards-checking/features-section";
import StartSavingsSection from "../../../components/start-savings-section/start-savings-section";

const SpanishInterestRewardsChecking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/checking-account/interest-rewards-checking/hero-interest-checking-040324-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "interest-rewards-checking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Man with shopping bags holding phone in big shopping mall.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Intereses, Recompensas y Más, Todo en Una Sola Cuenta",
            class: "text-white"
          }
        },
        {
          id: 3,
          button: {
            id: "interest-rewards-checking-hero-cta",
            class: "btn-white",
            text: "Abrir una cuenta",
            url: "/open-an-account"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es/banca-personal/cuenta-de-cheques",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Interest Checking"
    }
  ];

  const title = "Cuenta corriente con intereses: gane más en WaFd Bank",
    description =
      "Gane más en WaFd Bank con una cuenta corriente de recompensas que genera intereses y tiene muchas recompensas y ventajas que le ayudarán a ahorrar dinero. ¡Abra una cuenta hoy!";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/interest-rewards-checking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-interest-checking-040324.jpg"
      }
    ]
  };

  const iconListData = [
    {
      icon: "badge-percent",
      text: "Gana interés por cualquier saldo"
    },
    {
      icon: "money-check-alt",
      text: "Caja de cheques gratis (marca de WaFd)"
    },
    {
      icon: "key",
      text: 'Caja de seguridad de 3x5" de cortesía (donde esté disponible)'
    },
    {
      icon: "exchange-alt",
      text: "Reembolso de cargos por transferencias bancarias*"
    },
    {
      icon: "gas-pump",
      text: "Ahorro de 5¢ por galón de gasolina"
    },
    {
      icon: "tags",
      text: "Descuentos para compras"
    },
    {
      icon: "desktop",
      text: "Monitoreo de Dark Web"
    },
    {
      icon: "plane",
      text: "Descuentos para viajes y esparcimiento"
    },
    {
      icon: "mobile",
      text: "Protección para teléfonos celulares hasta $800 por reclamo, $1,000 por año"
    },
    {
      icon: "shield-check",
      text: "Seguro que reembolsa gastos por fraude de identidad de hasta $1,000,000"
    },
    {
      icon: "address-card",
      text: "Servicios de resolución de robo de identidad"
    },
    {
      icon: "user-shield",
      text: "Protección contra robo de identidad con monitoreo de archivos de crédito"
    },
    {
      icon: "chart-line",
      text: "Monitoreo de crédito"
    },
    {
      icon: "medkit",
      text: "Descuentos de salud"
    },
    {
      icon: "phone-volume",
      text: "Servicios de telesalud"
    },
    {
      icon: "car",
      text: "Asistencia en carretera 24 horas al día, 7 días a la semana"
    },
    {
      icon: "tachometer",
      text: "Simulador de Credit Score"
    },
    {
      icon: "file-certificate",
      text: " Protección del comprador y garantía extendida"
    },
    {
      icon: "umbrella",
      text: "Cobertura por muerte accidental y desmembramiento"
    },
    {
      icon: "shuttle-van",
      text: "Protección de viajes compartidos"
    }
  ];

  const iconListData1 = iconListData.slice(0, iconListData.length / 2);
  const iconListData2 = iconListData.slice(iconListData.length / 2, iconListData.length);

  const debitCardData = {
    sectionId: "interest-rewards-checking-debit-cards-section",
    titleText: "Abramos su cuenta hoy",
    depositText: "Con tan solo un depósito inicial de $25."
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Interest Checking</h1>
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnaill-interest-checking-040924.jpg"
              alt="Woman taking selfie with dog sitting in trunk of car outdoors."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              ¿Gana interés y recompensas mientras ahorras dinero? ¿En una cuenta de cheques? ¡Por supuesto!
            </h3>
            <p>
              Con nuestra cuenta más gratificante, puede disfrutar ganando interés más una amplia variedad de
              recompensas y beneficios por las cosas de la vida que más importan, como protección y resolución de robo
              de identidad, además de descuentos de gasolina y descuentos en compras en casi todas partes. Todo esto por
              $0-12 al mes, dependiendo de su saldo.
            </p>
            <div className="row align-items-center">
              <div className="col-sm-auto col-md-12 col-lg-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <Link
                  to="/open-an-account"
                  id="interest-rewards-open-account-cta"
                  className="btn btn-primary w-100 w-sm-auto"
                >
                  Abrir una cuenta
                </Link>
              </div>
              <div className="col-sm col-md col-lg">
                <Link
                  to="/es/banca-personal/tasas-de-interes"
                  id="interest-rates-link"
                  className="font-weight-bold text-decoration-none"
                >
                  Ver la tarifa de hoy
                  <Icon name="arrow-right" class="ml-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-success">Beneficios y recompensas de la cuenta</h2>
        <h3>
          Disfrute del acceso a beneficios valorados en más de $95 cada mes! El cargo mensual de cuenta no se aplica con
          una saldo promedio de $5,000 o saldo combinado de $50,000 en cuentas relacionadas.
        </h3>
        <div className="row">
          <div className="col-md-6">
            {iconListData1.map((data, index) => (
              <div className="row mb-3 no-gutters" key={index} id={`interest-rewards-list-item-1-${index + 1}`}>
                <div className="mr-3">
                  <Icon name={data.icon} lib="far" class="text-success fa-fw" />
                </div>
                <div className="col">{data.text}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            {iconListData2.map((data, index) => (
              <div className="row mb-3 no-gutters" key={index} id={`interest-rewards-list-item-2-${index + 1}`}>
                <div className="mr-3">
                  <Icon name={data.icon} lib="far" class="text-success fa-fw" />
                </div>
                <div className="col">{data.text}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <DebitCardsOpenAccount {...debitCardData} />
      <FeaturesSection />
      <StartSavingsSection isGray={true} />
      <ServiceStatesOpenAccountSection containerClass="bg-white" />
      <ContactInfo isSpanish={true} />
      <section className="container py-0 text-sm text-muted">
        <p id="interest-rewards-checking-disclaimer-1">
          Los beneficios están disponibles para los propietarios de cuentas de cheques personales y sus propietarios
          conjuntos de cuentas sujetos a los términos y condiciones de los Beneficios aplicables. Algunos Beneficios
          requieren autenticación, registro y/o activación. Los beneficios no están disponibles para un "firmante" de la
          cuenta que no sea propietario de la cuenta ni para empresas, clubes, fideicomisos, organizaciones y/o iglesias
          y sus miembros, o escuelas y sus empleados/estudiantes. Pueden aplicarse restricciones. Para obtener detalles
          adicionales, acceda a su beneficio de Ahorro de Gas a través de su banca en línea WaFd después del 20 de Mayo
          de 2024.
        </p>
        <p id="interest-rewards-checking-disclaimer-2">
          Notas del programa especial: Las descripciones aquí contenidas son solo resúmenes y no incluyen todos los
          términos, condiciones y exclusiones de los beneficios descritos. Consulte la Guía de beneficios y/o los
          documentos del seguro para obtener detalles completos sobre la cobertura y las exclusiones. La cobertura se
          proporciona a través de la empresa mencionada en la Guía de beneficios o en el certificado de seguro. La guía
          de beneficios y los documentos del seguro se pueden encontrar a través de su banca en línea WaFd o la
          aplicación móvil de WaFd Bank después del 20 de mayo de 2024. Si desea nombrar un beneficiario para su
          cobertura de seguro por muerte accidental y desmembramiento, complete un formulario de beneficiario. Si no hay
          ningún beneficiario designado registrado, los beneficios se pagarán de acuerdo con la Póliza Principal. Como
          propietario de una cuenta elegible, está inscrito como miembro de la Asociación Econocheck, de la cual su
          institución financiera es patrocinadora. Para obtener más información, llame al{" "}
          <a href="tel:866-210-0361" id="benefits-customer-service-tel-link">
            866-210-0361
          </a>
          . Los productos de seguro no están asegurados por la FDIC ni por ninguna agencia del gobierno federal; no es
          un depósito ni está garantizado por el banco ni por ninguna filial bancaria. Disponible para el titular de la
          cuenta y su cónyuge/pareja de hecho y hasta seis (6) hijos dependientes de 2 años o más. Esto no es un seguro.
          Credit Score es un VantageScore 3.0 basado en datos de una sola oficina. Terceros pueden utilizar un
          VantageScore diferente o un tipo diferente de puntaje crediticio para evaluar su solvencia crediticia. Una vez
          que haya activado el monitoreo de archivos crediticios, podrá solicitar su credit score. Una vez que lo hayas
          hecho, tendrás acceso a tu puntuación mensualmente. Los ahorros con descuento para la salud NO son un seguro.
          Se requiere registro/activación. Shopping Rewards requiere registro/activación; Disponible únicamente a través
          de celulares y la web. Los descuentos de viajes y esparsimiento son disponible únicamente a través de
          celulares y la web.
        </p>
        <p id="interest-rewards-checking-disclaimer-3">
          *Las tarifas de transferencia bancaria se reembolsan a pedido cuando su saldo mensual es de $5,000 o más, o
          tiene $50,000 o más en cuentas relacionadas.
        </p>
      </section>
    </SecondaryLanding>
  );
};
export default SpanishInterestRewardsChecking;
